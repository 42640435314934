// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../padloc/packages/pwa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../padloc/packages/pwa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../padloc/packages/pwa/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./SpaceMono-400.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./SpaceMono-700.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"Space Mono\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n\n@font-face {\n    font-family: \"Space Mono\";\n    font-style: normal;\n    font-weight: 700;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n}\n", "",{"version":3,"sources":["webpack://./../../../assets/fonts/SpaceMono/SpaceMono.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,4DAA+C;AACnD;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,4DAA+C;AACnD","sourcesContent":["@font-face {\n    font-family: \"Space Mono\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(./SpaceMono-400.woff2) format(\"woff2\");\n}\n\n@font-face {\n    font-family: \"Space Mono\";\n    font-style: normal;\n    font-weight: 700;\n    src: url(./SpaceMono-700.woff2) format(\"woff2\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
